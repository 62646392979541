/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ul: "ul",
    li: "li",
    a: "a",
    h3: "h3",
    em: "em"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "stdout.fm 60번째 로그에서는 GitHub Universe 2019, Kubecon 2019 참석기 등에 대해서 이야기를 나눴습니다."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.patreon.com/stdoutfm"
  }, "stdout.fm are creating 클라우드, 소프트웨어 개발, 전자 제품에 대해 이야기하는 프로그래머들의 팟캐스트 \\| Patreon")), "\n"), "\n", React.createElement(_components.h3, null, "Tesla Cybertruck"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.tesla.com/cybertruck"
  }, "Cybertruck | Tesla")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://twitter.com/elonmusk/status/1198772995021406209?ref_src=twsrc%5Etfw"
  }, "Elon Musk on Twitter: \"@RodAdib1 Yup. Sledgehammer impact on door cracked base of glass, which is why steel ball didn’t bounce off. Should have done steel ball on window, ", React.createElement(_components.em, null, "then"), " sledgehammer the door. Next time …\" / Twitter")), "\n"), "\n", React.createElement(_components.h3, null, "GitHub Universe 2019"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://githubuniverse.com/"
  }, "GitHub Universe · Nov 13 - 14, 2019 · San Francisco, CA | Join a community connected by code and turn ideas into innovations.")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://blogs.microsoft.com/blog/2018/10/26/microsoft-completes-github-acquisition/"
  }, "Microsoft completes GitHub acquisition - The Official Microsoft Blog")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://officesnapshots.com/2015/04/06/github-san-francisco-headquarters/"
  }, "GitHub - San Francisco Headquarters - Office Snapshots")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/features/actions"
  }, "GitHub Actions")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://about.gitlab.com/"
  }, "The first single application for the entire DevOps lifecycle - GitLab | GitLab")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://about.gitlab.com/product/continuous-integration/"
  }, "GitLab Continuous Integration & Delivery | GitLab")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://about.gitlab.com/product/serverless/"
  }, "GitLab Serverless | GitLab")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://help.github.com/en/actions/automating-your-workflow-with-github-actions/about-github-actions"
  }, "About GitHub Actions - GitHub Help")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://azure.microsoft.com/en-us/services/devops/pipelines/"
  }, "Azure Pipelines | Microsoft Azure")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/mobile"
  }, "GitHub for mobile")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://archiveprogram.github.com/"
  }, "GitHub Archive Program | The GitHub Archive Program will safely store every public GitHub repo for 1,000 years in the Arctic World Archive in Svalbard, Norway.")), "\n"), "\n", React.createElement(_components.h3, null, "Kubecon 2019"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://events19.linuxfoundation.org/events/kubecon-cloudnativecon-north-america-2019/"
  }, "KubeCon + CloudNativeCon North America 2019 - Linux Foundation Events")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://events19.linuxfoundation.org/events/kubernetes-forum-seoul-2019/"
  }, "Kubernetes Forum Seoul 2019 - Linux Foundation Events")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://helm.sh/docs/intro/install/"
  }, "Helm | Installing Helm")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://vitess.io/"
  }, "Vitess | A database clustering system for horizontal scaling of MySQL")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.openpolicyagent.org/"
  }, "Open Policy Agent")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/rkt/rkt"
  }, "rkt/rkt: rkt is a pod-native container engine for Linux. It is composable, secure, and built on standards.")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/kubernetes/sig-release/tree/master/release-team"
  }, "sig-release/release-team at master · kubernetes/sig-release")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/grafana/loki/releases/tag/v1.0.0"
  }, "Release v1.0.0 · grafana/loki")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://prometheus.io/"
  }, "Prometheus - Mthanos-io/thanos: Highly available Prometheus setup with long term storage capabilities. CNCF Sandbox project.onitoring system & time series database")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/cortexproject/cortex"
  }, "cortexproject/cortex: A horizontally scalable, highly available, multi-tenant, long term Prometheus.")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/thanos-io/thanos"
  }, "thanos-io/thanos: Highly available Prometheus setup with long term storage capabilities. CNCF Sandbox project.")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://grafana.com/"
  }, "Grafana: The open observability platform | Grafana Labs")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://argoproj.github.io/argo-cd/"
  }, "Argo CD - Declarative GitOps CD for Kubernetes")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.workshopcafe.com/"
  }, "Workshop Cafe | Food. Workspace. Productivity.")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.instagram.com/p/B4xojA4FblL/?igshid=ucoctx7x4xcw"
  }, "Outsider on Instagram: “주로 가던 Workshop Cafe가 없어져서 Covo라는 코워킹 스페이스로 왔다. 쾌적하니 좋군.(시간당 $4)”")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
